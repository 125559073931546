.searchBox {
    background-color: transparent !important;
    border: 1px solid grey;
    width: 20rem;
    height: 2.1rem;
    outline: none;
}

.starLine {
    margin-top: 0.5rem;
}

.starLine2 {
    margin-top: 0.7rem;
}

.fs30 {
    font-size: 30px;
}

.revLine1 {
    position: absolute;
    top: 88rem;
    left: 9rem;
}

.revSize {
    font-size: 3rem;
}

.starHold2 {
    position: absolute;
    top: 93rem;
    left: 37rem;
}


.feelHold {
    top: -52rem;
    left: 35rem;
}

.feelImg {
    width: 24rem;
}

.filterRevHold {
    top: -46rem;
    left: 11rem;
}

/*  here height can be used be as gap  */
.searchList {
    top: -32rem;
    left: 11.5rem;

    width: 70rem;
    height: 12rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 80rem;  

}

.w50rem{
    width: 50rem;
}

.firstLetterHold {
    background-color: #83b6ddc9;
    width: 35px;
    height: 35px;
    left: -3.7rem;
    top: -6.9rem;

}



.keyWord {
    padding: 8px 9px 8px 11px;
    background-color: rgba(48, 133, 199, 0.07);
    cursor: pointer;
}

.w40rem {
    width: 40rem;
    /* position: relative; */
    top: 117rem;
    left: 11rem;
    position: absolute;

}


.popularKeyWordsText {
    top: 111.3rem;
    left: 32rem;
    margin-bottom: 0rem;
}