body {
    font-family: "Source Sans 3";
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden !important;
    min-height: 500vh !important; /* Added min-width property */
}

.linHei {
    line-height: 4rem !important;
}

.linHei2 {
    line-height: 2rem !important;
}

.user-profile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
}

.bla {
    background-color: #252525 !important;
}

* {
    box-sizing: border-box !important;
}

.MuiInput-formControl {
    margin-top: 30px !important;
}

.profile-cart {
}

.profile-cart li {
    padding: 0 !important;

}

li a {
    align-items: center !important;
}

img {
    max-width: 100%;
    height: auto;
}

.cg {
    left: 37rem;
}

.hei {
    height: 3rem;
}

.bor {
    border: 1px solid #dddddd;
}

.cartName {
    width: 13rem;
    top: -14.4rem;
    left: 31.5rem;
    font-size: 17px !important;

}

.spAddTOCart {
    background-color: black;
    width: 14em;
    color: white;
    height: 3rem;
    margin-top: 1em;
    transition: all 400ms ease;
}

.spAddTOCart:hover {
    transform: scale(1.08);
}

.MuiPaper-rounded {
    margin: 2em !important;
    width: 100% !important;
}

.cart-header {
    margin-left: 2em !important;
}

.card-content {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.spLogoutBt {
    background-color: black;
    width: 5rem;
    color: white;
    height: 2rem;
    margin-top: 1em;
    transition: all 400ms ease;
    font-style: italic;
}

.spLogoutBt:hover {
    background-color: transparent !important;
    color: black !important;
    border: 1px solid black;
    transform: scale(1.08);
}

.cart-page-grid-item {
    margin: 100em;
}

.price-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.CartTotal {
    width: 40%;
}

.cartList {
    display: block;
    width: 60%;
    margin-left: 10em;
}
.price-details {
    width: 80%;
}

.address-card {
    display: flex;
    flex-direction: row;
    width: 100% !important;
    justify-content: space-between !important;
}

.address-card button {
    font-size: small;
    width: 15em;
}

.cart-grid {
    justify-content: space-around  !important;
}

.cart-total-div {
    display: block;
}

.total-summary {
    border-top: 1px solid #dddddd;  
}

.checkoutBtn {
    width: 100% !important;
}

.cart-total-header {
    border-bottom: 1px solid #dddddd;  
}

.profile-btn {
    width: 15%;
}

.MuiDialog-paper{
    width: 300rem !important;
}

.MuiGrid-root {
    justify-content: space-around !important; 
}

.cartPrice {
    width: 6rem;
    top: -13rem;
    left: 34.5rem;
}

.cartImaegHold {
    left: 19rem;
    top: -4rem;

}

.qtyhChangehold {
    position: relative;
    left: 31rem;
    top: -12rem;
    display: flex !important;
    flex-direction: column !important;
}

.cartProductsHold {
    top: 40rem !important;

}

/*  chnageing  "cartProductsHold" from 3 rem to 40rem  */



.totLine {
    left: 2rem;
    top: -5rem;
}

.totLine2 {
    left: 2rem;
    top: -3.5rem;
}

.middleLine {
    top: -5rem;
    left: 18rem;
}

.youMay {
    top: 2rem !important;
    position: relative !important;
}

.similar-product {
    margin-top: 4em !important;
    margin-left: 4em !important;
}

.paypal-button::before {
    content: "PAY WITH PAYPAL" !important;
}

.paypal-button::after {
    content: "PAY WITH PAYPAL" !important;
}

.urCartItem {
    left: 18rem;
    top: 2rem;
    gap: 23rem !important;
}

.home-page {
}

.lin {
    line-height: 3rem !important;
}

.mobHold{
    position: relative !important;
}

.cartTotalMainParent {
    box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
    background-color: white;
    border: 2px solid whitesmoke;
    width: 28rem;
    height: 37rem;
    position: absolute !important;
    left: 62rem;
    top: 16rem;
    border-radius: 15px;
}

/* changes "cartTotalMainParent" from relative to absolute  */

.idk {
    top: 80rem !important;
    position: relative !important;
}

.chParent {

    position: relative;
    top: -30rem;
    left: 0rem;
}

.calcHold {
    top: -6.5rem;
    left: 20rem;
}

.topLine {
    top: 32rem;
    left: 18rem;
}

.paypal-button-container {
    width: 74% !important;
}

.ctActualToatal {
    left: 10rem;
    top: -4rem;
}

.licart {
    line-height: 5rem;
}

.payc {
    position: relative;
    top: -5rem;
    left: 11.4rem;
    color: white;
    z-index: 9999999999;
    background-color: #2C2E2F;
    width: 7rem;
}


.featHeadingHold {
    top: -20rem;
    left: 12rem;
}

.gpayHold {
    position: relative;
    top: -0.5rem;
}

.gpay-card-info-container.black,
.gpay-card-info-animation-container.black {
    width: 27.4rem;
}





/*.searchItemsHold{
    height: 20rem !important;
}*/













/*  PYAPAL  BUTTON */

.paypal-button.paypal-button-color-gold,
.paypal-button-row.paypal-button-color-gold .menu-button {
    background-color: black !important;
}

.paypal-button.paypal-button-color-gold,
.paypal-button-row.paypal-button-color-gold .menu-button {
    background: black !important;
}

/*  YOU MAY ALSO LIKE AUTO SCROLLING    */


.alsoLikeText {
    top: 27rem;
    left: 29.5rem;
    font-size: 45px !important;
}

.cpf{
    top: 39rem !important;
}

.boxImg {
    box-shadow: 10px 10px 5px #ccc;
    -moz-box-shadow: 10px 10px 5px #ccc;
    -webkit-box-shadow: 10px 10px 5px #ccc;
    -khtml-box-shadow: 10px 10px 5px #ccc;
}

.faqParent {
    top: 4rem;
    right: -14rem;
    width: 70vw;
    position: relative !important;
}



@tailwind base;
@tailwind components;
@tailwind utilities;


.zi {
    z-index: 9999999999;
}

/* code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
} */

.car-pic {
    width: 100vw;
    max-width: 100% !important;
    object-fit: cover;
}

.slick-prev {
    display: block !important;
    left: 2rem !important;
    top: 19rem !important;
    z-index: 99999999;
    width: 4rem !important;
}

.slick-next {
    right: 3.5rem !important;
    z-index: 9999999999999999 !important;
    top: 19.7rem !important;

}

.slick-prev:before {
    content: url("../src/assets/prevv.png") !important;
}

.slick-next:before {
    content: url("../src/assets/nf.png") !important;
}