.ourBestSellersImg {
    width: 19rem !important;
    object-fit: contain !important;
}

.best-seller-image {
    width: 15rem;
    height: 15rem;
}

.box-shadow {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.zoom-item {
    transition: transform 0.3s;
    &:hover {
        transform: scale(1.02);
    }
}

.ourBestSellersMainParent {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 85px;
    position: relative;
    width: 104vw;
    margin-top: 3rem;
    right: -6.5rem;

}

:root{
    --p: 293 194% 8% !important;
    --pf: 293 194% 8%  !important;

}


.zoom{
    transition: all 360ms ease;
}

.zoom:hover{
    transform: scale(1.08);
}

.fof{
    font-family: Arapey, Helvetica, Arial, sans-serif;
    
}