.category-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    width: 90vw;
    margin: 0 auto;
}

.category-title {
    margin-top: 3rem;
    margin-bottom: 2rem;
    text-align: center;
    font-size: 2rem;
}